
  /*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/
@import "../milligram/Color";    // import your general color from milligram

/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
// @import "../component/popup";
@import "../pages/general";

h1{
  color: #fff;
  margin-bottom: 19px;
  b{
    font-size: 5.375rem;
  }
}
p{
  color: #fff;
  margin-bottom: 32px;
}
.circle-404{
  position: relative;
  background: url('/images/material/big-circle-white.png') no-repeat center;
  background-size: 100%;
  text-align: center;
  width: 532px;
  margin: 0 auto;
  padding: 0 10px;
  min-height: 423px;
  .box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    h1{
      color: #fff;
      margin-bottom: 19px;
      b{
        font-size: 5.375rem;
      }
    }
    p{
      color: #fff;
      margin-bottom: 19px;
    }
  }
}
.box-maintenance{
  text-align: center;
  padding-top: 135px;
  h1{
    color: #fff;
    margin-bottom: 19px;
    font-size: 4.688rem;
    display: inline-block;
    position: relative;
    &:after {
      position: absolute;
      width: 23px;
      height: 33px;
      top: 30px;
      right: -40px;
      content: ""; 
      background: url('/images/material/union-1.png') no-repeat 0 0; 
    }
    &:before {
      position: absolute;
      width: 23px;
      height: 33px;
      top: 30px;
      left: -40px;
      content: ""; 
      transform: rotate(180deg);
      background: url('/images/material/union-1.png') no-repeat 0 0; 
    }
    b{
      font-size: 4.313rem;
    }
  }
  p{
    color: #fff;
    margin-bottom: 19px;
  }
}
footer{
  .footer-top{
    min-height: 261px;
  }
}
.acc-list{
  text-align: left;
  margin-top: 70px;
  margin-bottom: 37px;
  position: relative;
  z-index: 9; 
  .accordion{
    padding-bottom: 16px;
    .head{
      background:#fff;
      padding: 24px;
      padding-right: 54px; 
      display: flex;
      position: relative;
      transition:all .25s ease-out; 
      h6{
        color: #333333;
        font-weight: 800;
        text-transform: none;
        margin-bottom: 10px;
      }
      ul{
        display: flex;
        margin-bottom: 0; 
        li{
          list-style: none;
          font-weight: 800;
          font-size: 0.75rem;
          margin-right: 8px;
          padding-left: 14px;
          position: relative;
          margin-bottom: 0; 
          &.purple{
            color: #402566;
          }
          &.pink{
            color: #FC1368;
          }
          &:before{
            content: "";
            position: absolute;
            background: #C4C4C4;
            height: 6px;
            width: 6px;
            border-radius: 100%;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
          &:first-child{
            padding-left: 0;
            &::before{display: none;}
          }
        }
      }
      .ico{
        display: none;  
        position: absolute;
        content: "";
        background:url(/images/material/arrow-h.svg) no-repeat center;
        background-size: 100%;
        height: 17px;
        width: 11px;
        right: 24px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        transition:all .3s ease-out; 
      }
      &:hover {
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
      }
    }
    .content{
      display: none;
      padding: 0 24px 24px 24px; 
      background:#fff;
    }
    &.active{
      .head{
        .ico{
          transform: translateY(-50%) rotate(-90deg);
          transition:all .3s ease-out; 
        }
      }
    }
  }
}
.list-result{
  margin-top: 40px;
  .list{
    margin-bottom:40px;
    display: block;
    position: relative;
    padding-bottom: 1px;
    h5{
      color: #402566;
      font-weight: 600;
    }
    p{
      color: #939393;
    }
    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: url('/images/material/line-2.png')no-repeat center;
      background-size: cover;
      width: 100%;
      height: 4px;
    }
    &:last-child{
      margin-bottom: 0; 
      padding-bottom: 0; 
      &::after{
        display: none;
      }
    }
  }
}
.wrap-search{
  input{
    border: none;
    background: url('/images/material/ic-search.svg')no-repeat 98% 10%;
    padding: 0 70px 40px 0;
    font-size: 39px;
    height: auto;
    position: relative;
    &::placeholder{
      font-size: 39px;
      font-weight: 600;
      color: #FC1368;
    }
    
  }
  .form{
    position: relative;
    margin-bottom: 20px;
    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background: url('/images/material/line.png')no-repeat center;
    }
  }
}

.content-pages {
  h1,h2,h3,h4,h5,h6 {
    b {
      font-family: 'Montserrat'; 
      font-weight: 700;   
    }
  }
  ul {
    li {
      padding-left: 23px;
      line-height: 1.9;  
      &:before {
        width: 8px; 
        height: 8px; 
        background: #C4C4C4; 
        border-radius: 50%; 
        top: 9px; 
        margin: 0;         
      }
    }
  }
  ol {
    li {
      padding-left: 23px;
    }
  }
}

.career-detail {
  .title-text {
    margin-top: 70px; 
  }
}

.info-job {
  display: flex; 
  margin-bottom: 24px; 
  span {
    display: inline-block;
    height: 24px;
    padding-left: 27px;
    margin-right: 24px;  
    color: #402566; 
    font-weight: 600; 
    &.date {
      background: url('/images/material/i-calendar.svg') no-repeat 0 center;    
      background-size: 25px 24px;   
    }
    &.location {
      background: url('/images/material/i-location.svg') no-repeat 0 center; 
      background-size:17px 18px; 
    }    
  }
}
.spec-job {
  background: $color-initial;  
  padding: 32px 146px 13px 32px;  
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 40px; 
  span {
    color: #808690; 
  }
}

.privacy {
  p {
    color: #939393; 
  }
}

.wrap-clone {
  position: relative;
  .button {
    font-weight: normal;
    height: 42px; 
    line-height: 42px; 
    background: url('/images/material/bg-add.png') no-repeat center;
    background-size: 100% 100%; 
    padding: 0 15px; 
    font-size: 12px; 
    position: absolute;
    top: -5px; 
    right: 0; 
    margin: 0; 
    z-index: 3; 
    border:0; 
    &:hover {
      background: url('/images/material/bg-add.png') no-repeat center;
      background-size: 100% 100%; 
    } 
    &:hover {
      opacity: .8; 
    }
    &.remove {
      background: url('/images/material/bg-remove.png') no-repeat center center; 
      background-size: 100% 100%; 
      top: -5px; 
    }
  }
  .title-form {
    position: relative; 
  }
  .rowclone {
    margin-bottom: 20px; 
    &:first-child {
      .remove {
        display: none;
        z-index: -5; 
      } 
    }
  }
}


/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) { 
  .box-maintenance {
    padding-top: 50px; 
    width: 504px;
    margin: auto; 
    h1 {
      font-size: 3rem;
      b {
        font-size: 2.5rem; 
      }
      &:before,
      &:after {
        top: 20px; 
      }
    }
  }

  .wrap-search {
    form {
      ::placeholder {
        font-size: 1.75rem; 
      }
    }
    input {
      font-size: 1.75rem; 
      padding-bottom: 24px; 
      padding-right: 50px;
      background-size: 20px;
      line-height: 1;    
    }
  }

  .list-result {
    .list {
      margin-bottom: 32px; 
      &:last-child {
        padding-bottom: 0; 
        margin-bottom: 0; 
      }
    }
  }

  .circle-404 {
    width: 490px; 
    .box {
      width: 100%; 
      h1 {
        font-size: 2rem; 
        b {
          font-size: 4rem; 
        }
      }
    }
  }

  .career-detail {
    .title-text {
      margin-top: 50px; 
    }
}

  footer {
    .footer-top {
      min-height: 200px;
    }
  }

}


/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  p {
    margin-bottom: 19px; 
  }
  .middle {
    min-height: 350px;
    &.no-banner {
      .img-left-abs {
        width: 180px; 
      }
      .img-right-abs {
        width: 180px; 
      }
    }
    &.misc-pages {
      .img-left-abs {
        // width: 100px; 
        &.small {
          width: 75px;
        }
      }
      .img-right-abs {
        // width: 100px; 
        &.small {
          width: 88px; 
        }
      }
      .box-maintenance {
        width: 397px; 
      }
    }
  }
  .box-maintenance {
    h1 {
      font-size: 2rem; 
      b {
        font-size: 2rem;  
      }
      &:before,
      &:after {
        top: 5px; 
      }
      &:before {
        left: -30px;
      }
      &:after {
        right: -30px; 
      }
    }
  }

  .wrap-search { 
    form {
      ::placeholder { 
        font-size:1.5rem; 
      }
    }
    input {
      font-size: 1.5rem; 
    }
  }

  .list-result {
    .list { 
      margin-bottom: 20px; 
    }
  }

  .circle-404 {
    width: 370px; 
    min-height: 320px; 
    .box {
      padding:0 50px;
      h1 {
        font-size: 1.5rem; 
        b {
          font-size: 3rem; 
        }
      }
    }
  }

  .box-maintenance {
    width: 252px;
    h1 {
      &:before {
        width: 20px; 
        height: 30px; 
        background-size: 20px 30px;
      }
      &:after {
        width: 20px; 
        height: 30px; 
        background-size: 20px 30px;
      }
    }
  }

  .acc-list {
    .accordion {
      .head {
        padding-top: 13px; 
        padding-bottom: 13px;  
        h6 {
          font-size: .875rem; 
        }
      } 
    }
  }

  .spec-job {
    padding: 32px 32px 13px 32px; 
  }

  footer {
    .footer-top {
      min-height: 100px;
    }
  }

}


/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .box-maintenance {
    width: 225px; 
    padding: 165px 0 40px 0;  
    h1 {
      font-size: 1.5rem; 
    }
  }

  .content-pages {
    ul {
      li {
        &:before {
          top: 7px; 
        }
      }
    }
  }

  .middle {
    &.no-banner {
      padding-bottom: 100px;
      .img-left-abs {
        bottom: unset; 
        top: 0; 
      }
      .img-right-abs {
        top: 70%; 
        right: 0;
      }
    }
    &.misc-pages {
      overflow-x:hidden;  
      .img-left-abs {
        bottom: unset;
        top: 0; 
        width: 180px;
      }
      .img-right-abs {
        width: 180px;
        &.small {
          width: 80px; 
        }
      }
      .img-top-left {
        left: -70px; 
        &.med {
          width: 160px; 
        }
      }
      .box-maintenance {
        width: 280px; 
      }
    }
    &.maintenance {
      .img-left-abs {
        top: -35px; 
      }
      .img-right-abs {
        top: 72%;
        right: -35px; 
      }
      .box-maintenance {
        padding: 140px 0 40px; 
      }
    }
    &.notfound {
      .img-left-abs {
        top: 50px; 
        width: 100px;
      }
      .img-right-abs {
        width: 100px;
        top: 33%; 
      }      
      .wrapper-middle {
        width: 100%;  
      }
    }
  }

  .wrap-search {
    form {
      ::placeholder {
        font-size: 1.25rem;
      }
    }
    input {
      font-size: 1.25rem; 
      padding-bottom: 16px; 
    }
  }
  .circle-404 {
    width: 240px;
    height: 200px;
    min-height: 200px;
    background-size: 100% 100%; 
    .box {
      padding: 0 10px; 
      margin-top: -10px; 
      p {
        margin: 0 15px 6px 15px; 
      }
      h1 {
        font-size: 1rem; 
        margin-bottom: 10px;
        b {
          font-size: 1.5rem; 
        }
      }
      .button {
        letter-spacing: normal; 
      }
    }
  }

  .acc-list {
    margin-top: 30px; 
    .accordion {
      .head {
        padding: 16px 54px 16px 16px; 
      }
    }
  }

  .spec-job {
    padding: 20px 20px 13px 20px; 
    .row {
      .column {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .info-job {
    span {
      line-height: 24px; 
    }
  }

  .wrap-clone {
    .button {      
      height: 34px; 
      line-height: 34px; 
      span {
        display: none; 
      }
    }
  }

  .list-result {
    margin-top: 30px; 
  }

  footer {
    .footer-top {
      .wrapper {
        .box-left {
          text-align: center; 
          p {
            margin-bottom: 0; 
          }
        }
        .box-follow {
          justify-content: center; 
        }        
      }
    }
    .footer-bottom {
      .wrapper {
        .other-link {
          right: 0; 
          margin: auto; 
          top: -42px; 
          ul {
            justify-content: center; 
          }
        }
      }
    }
  }

}