@import "../milligram/Color";    // import your general color from milligram

/* general styling
----------------------------------------------------------------------------------------------*/

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    overflow: hidden;
    .innerLoad {
    	display: flex; 
    	align-items: center; 
    	justify-content: center; 
    	height: 100%; 
    	position: relative; 
    	z-index: 5; 
    	flex-direction: column; 
    	.logo-load {
    		margin-bottom: 40px;
    		width: 290px; 
    	}
    	.line-load {
	        position: relative; 
	        height: 7px; 
	        left: 0; 
	        right: 0; 
	        top: 0; 
	        z-index: 9;
	        border-radius: 3px; 
	        width: 450px;
	        &:before {
	        	position:absolute; 
	        	width: 100%; 
	        	height: 7px; 
	        	content: ""; 
	        	background: #fff; 
	        	opacity: .2;  
	        	border-radius: 3px;
	        }
	        span {
	        	display: block; 
	        	height: 7px; 
	        	width: 0; 
	        	background: #FC1368;
	        	border-radius: 3px;
	        }
	    }
    }    
    .bg-load {
        position: fixed; top: 0; left: 0; width: 100%; height: 100%;  
        display: block; background: #402566; z-index: 2;        
    }
}

.slick-arrow {
	position: absolute;
	width: 45px;
	height: 45px; 
	top: 50%;
	transform:translateY(-50%); 
	background: url('/images/material/bullet-2.svg') no-repeat 0 0;
	padding: 0; 
	border:0; 
	font-size: 0; 
	z-index: 5; 	
	background-size: 45px 45px; 
	&:after {
		position: absolute;
		width: 100%;
		height: 100%;
		content: "";
		left: 0; 
		top: 0; 
		background: url('/images/material/arrow-h.svg') no-repeat center center; 
		background-size: 11px 15px; 
		transition:all .3s ease-out; 
	}
	&.slick-prev {
		left: 32px; 
		transform:translateY(-50%) rotate(-180deg); 
	}
	&.slick-next {
		right: 32px;  
	}
	&.slick-disabled {
		cursor: not-allowed; 
		opacity: .5; 
	}
	&:hover {
		&:after {
			left: 4px; 
		}		
		&.slick-disabled {
			&:after {
				left:0;
			}
		}
	}
}

.slick-dots {
	position: absolute;
	width: 100%; 
	max-width: 1170px;
	display: block;  
	bottom: 48px; 
	left: 50%; 
	transform:translateX(-50%); 
	margin-bottom: 0; 
	height: 9px; 
	display: flex;
	justify-content:center;
	z-index: 5; 
	li {
		display: inline-block;
		margin-right: 10px; 
		button {
			width: 9px;
			height: 9px; 
			padding: 0;
			border:0; 
			background: $color-initial;
			opacity: 1; 
			border-radius: 3px;
			transform:scale(.7);
			font-size: 0; 
			transition:all .3s ease-out; 
			&:before,
			&:after {
				display: none;
			}
		}
		&.slick-active {
			button {
				width: 30px;
				opacity: 1; 
				transform:scale(1); 
			}
		}
	}
}

.h-title {
	display: flex;
	justify-content: space-between; 
	color: #333; 
	margin-bottom: 50px; 
	align-items: flex-end; 
	.txt {
		h3 {
			display: inline-block;
			position: relative; 
			padding-right: 32px;
			margin-bottom: 12px;  
			&:after {
				position: absolute;
				width: 23px;
				height: 33px;
				top: 0; 
				right: 0;
				content: ""; 
				background: url('/images/material/union-1.png') no-repeat 0 0; 
			}
		}
		p {
			color: #333;
			font-weight: 500; 
		}
	}
}

.product-list {
	display: flex;	
	.list {
		margin: 0 15px; 
		figure {
			margin-bottom: 18px; 
			height: 280px; 
			width: 100%; 
			position: relative; 
			.new {
				position: absolute;
				top: 5px; 
				right: 5px; 
				width: 111px;
				height: 110px;
				background: url('/images/material/bg-new.png') no-repeat 0 0; 
				color: $color-initial; 
				font-size: 1.5rem;
				line-height: 110px;
				text-align: center; 
				font-family: 'Luckiest Guy';
				z-index: 3;  
			}
			img {
				transition:all .3s ease-out;
				position: absolute;
				top: 0; 
				left:0;
			}
			.hover {
				visibility: hidden;
				opacity: 0; 
				transform:scale(.8);
				position: absolute;
				width: 100%;
				height: 100%; 
				top: 0; 
				left: 0; 
				transition:all .3s ease-out;
				img {
					transition:unset;   
					left: 50%;
					top: 50%;
					transform:translate(-50%, -50%); 
				}
				&:before {
					position: absolute;
					width: 241px;
					height: 241px; 
					top: 50%;
					transform:translate(-50%, -50%);
					left: 50%;
					z-index: -1; 
					content: ""; 
					background:url('/images/material/product-hover.png') no-repeat 0 0;  
					transition:all .2s ease-out;
				}				
			}
		}
		figcaption {
			h4 {
				color: $color-initial; 
				text-align: center; 
				font-size: 2.25rem; 
				b {
					font-weight: 400;
					font-size: 1.5rem; 
					display: block; 
				}
			}
		}
		&:hover {
			figure {
				img {
					&.default {
						visibility: hidden;
						opacity: 0; 
						transform:scale(.9);  
					}					
				}
				.hover {
					visibility: visible;
					opacity: 1; 
					transform:scale(1);
					&:before {
						transform:translate(-50%, -50%) rotate(180deg);
					}
				}
			}
		}
	}
	&.onlanding{
		flex-flow: row wrap;
		margin: 0 -15px;
		.list{
			flex: 0 0 25%;
			max-width: 25%;
			padding: 0 15px;
			margin: 0 0 60px;
			box-sizing: border-box;
			figcaption{
				h4{
					color: #402566;
				}
			}
		}
	}
}

.middle{
	padding: 60px 0px 120px;
	position: relative;
	min-height: 600px;
	background: #FCFAF2; 
	overflow: hidden; 
	.wrapper, 
	.wrapper-middle {
		z-index: 10; 
	}
	&.overflow-visible{
		overflow: visible;
	}
	.title-text{
		color: #FC1368;
		font-weight: bold;
	}

	&.nopadding-bot{
		padding-bottom: 0;
	}
	&.detail-pages{
		padding-top: 135px;
		.img-top-left {
			top: 78px;
		}
		.form {
			margin-top: 0; 
		}
	}
	.img-top-left{
		position: absolute;
		top: 0;
		left: 0;
	}
	.img-top-right{
		position: absolute;
		top: 0;
		right: 0;
	}
	.img-bottom-left{
		position: absolute;
		bottom: 155px;
		left: 0;
	}
	.img-left-abs{
		position: absolute;
		left: 0;
		top: 444px; 
		z-index: 5;	
		width: 180px; 
		&.small {
			width: 120px; 
		}
		&.top-50p {
			top: 55px; 
		}
		&.top-40 {
			top: 40px; 
		}	
		&.from-bottom {
			top: unset; 
			bottom: 0; 
		}
		&.from-bottom-200 {
			top: unset;
			bottom: 200px;  
		}
		&.from-bottom-min {
			top: unset;
			bottom: -220px;  
		}
	}
	.img-right-abs{
		position: absolute;
		right: 0;
		top: 318px;
		z-index: 1;		
		&.small {
			width: 110px; 
		}
		&.top-20 {
			top: 20%; 
		}
		&.top-130p {
			top: 145px; 
		}
		&.top-40 {
			top: 40%; 
		}
		&.top-42 {
			top: 42%; 
		}
	}
	&.withbackground-paper{
		// background:#FCFAF2 url('/images/material/bg-story.png') repeat 0 0;
		background-size: auto;
	}
	&.bg-cream-paper{
		// background:#FCFAF2 url('/images/material/bg-story.png') repeat 0 0;
		background-size: auto; 
	}
  &.no-banner{
    padding-top: 135px;
    background: #402566;
  }
  &.misc-pages{
    height: calc( 100vh - 310px );
    background: #402566;
    overflow-y: hidden;
    &.notfound {
    	display: flex; 
    	align-items: center; 
    	padding-bottom: 30px; 
    }
  }
}

.content-pages{
	h1,h2,h3,h4,h5,h6{
		font-family: Montserrat;
	}
	p{
		color: #292929;
		font-weight: 500;
		letter-spacing: normal; 
	}
	ul{
		li{
			padding-left: 40px;
			list-style: none;
			margin-bottom: 10px;
			color: #333;
			font-weight: 500;
			position: relative;
			&:before{
				content:'';
				position: absolute;
				left: 0;
				background: url('/images/material/bullet-small.svg')no-repeat center;
				top: 0;
				bottom: 0;
				margin: auto 0;
				width: 16px;
				height: 16px;
			}
		}
	}
}

.bg-blue-wave{
	position: relative;
	background:#402566;
	margin-top: 140px;
	padding-top: 150px;
	padding-bottom: 50px;
	.img-left-abs {
		top: unset;
    bottom: 100%;
    margin-bottom: -205px;
	}
	&:before{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		background:url('/images/material/wave-blue.png')no-repeat center top;
		height: 110px;
		width: 100%;
		bottom: 100%;
		background-size: cover;
	}
	.wrapper{
		position: static;
	}
	.line-wave{
		position: absolute;
		left: 0;
		top: -80px;
	}
	.line-dashed{
		position: absolute;
		right: 0;
		top: -68px;
	}
	.line-seed{
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.rounded-title{
		position: absolute;
		top: -120px;
		left: 0;
		right: 0;
		margin: 0 auto;
		background: url('/images/material/rounded-title.png')no-repeat center;
		width: 276px;
		height: 205px;
		display: flex;
		justify-content: center;
		flex-flow: column nowrap;
		align-items: center;
		color: #fff;
		padding: 30px;
		h4{
			margin-bottom: 0;
		}
		span{
			font-size: 5rem;
			line-height: 1;
			font-family: 'Fredericka the Great';
			position: relative;
			&::before,&::after{
				content:'';
				position: absolute;
				right: 100%;
				top: 0;
				bottom: 0;
				background: url('/images/material/kutip.png')no-repeat center;
				width: 16px;
				height: 23px;
				margin: auto 0;
			}
			&::after{
				left: 100%;
				right: auto;
				transform: rotate(180deg);
			}
		}
	}
	&.less-top{
		padding-top: 50px;
	}
	&.wavebottom{
		margin-bottom: 170px;
		&:after{
			content:'';
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			background: url('/images/material/wave-blue-bottom.png')no-repeat center bottom;
			height: 90px;
			width: 100%;
			background-size: cover;
		}
	}
}

.banner-middle{
	figure{
		position: relative;
		img{
			width: 100%;
			display: block;
		}
		.text{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 30%;
			transform: translateY(-30%);
			z-index: 2;
			text-align: center;
			color: #fff;
			h2{
				font-size: 3rem;
			}
			span{
				background: url('/images/material/bg-text-orange.png')no-repeat center;
				display: inline-block;
				background-size: contain;
				font-weight: bold;
				padding: 15px 25px;
				text-transform: uppercase;
			}
		}
	}
}

.ice-list {
	figure {
		margin-bottom: 13px; 
		height: 251px; 
		display: flex;
		justify-content: center;
	}
	figcaption {
		border-radius: 11px;
		background: $color-initial; 
		border:1px solid #402566; 
		box-shadow: 4px 4px 1px #402566;
		padding: 8px 12px;  
		display: flex; 
		align-items: center; 
		min-height: 85px; 
		margin: 0 55px; 
		.t-ico {
			font-size: 3rem; 
			color: #FC1368;
			font-weight: 400;  
			font-family: 'Luckiest Guy';  
			padding-right: 20px; 
			display: inline-block;
			line-height: 1;   					
		}
		.txt {
			h6 {
				margin-bottom: 3px;  
				font-family: "Montserrat"; 
				color: $color-primary;
				font-weight: 700;  						
			}
			p { 
				margin-bottom: 0; 
				font-size: .875rem; 
			}
			&.md {							
				width: 100%; 
				text-align: center; 
				h6 {
					color: #FC1368;
					font-weight: 400;  
					font-family: 'Luckiest Guy';
					font-size: 1.5625rem; 
				}
			}
		}
	}
	.caption {
		.slick-list {
			padding-bottom: 10px;   
		}
	}
	.slick-arrow {
		top: unset;
		transform: translateY(0); 
		bottom: 25px; 
		&.slick-prev {
			left: -5px; 
			transform: translate(0) rotate(-180deg);  
		}
		&.slick-next {
			right: -5px; 
		}
	}
	.slick-dots {
		bottom: -20px; 
		li {
			button {
				background: $color-primary; 
			}
		}
	}
}

.filter-pages{
	display: flex;
	.col{
		display: flex;
		align-items: center;
		label{
			color: #FC1368;
			font-size: 0.875rem;
		}
		select{
			width: 195px;
			margin-left: 25px;
			border: 1px solid #DFDFDF;
		}
	}
	.col-auto{
		margin-left: auto;
	}
}

.pagination{
	display: flex;
	justify-content: center;
	align-items: center;
	.arr{
		background: url('/images/material/bullet-shadow.png') no-repeat center;
		position: relative;
		width: 50px;
		height: 50px; 
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0; 
			top: 0; 
			background: url('/images/material/arrow-h.svg') no-repeat center center; 
			background-size: 11px 15px; 
			transition:all .3s ease-out; 
		}
		&.prev{
			margin-right: 20px;
			&:after{
				transform: rotate(180deg);
			}
		}
		&.next{
			margin-left: 20px;
		}
	}
	a{
		margin: 0 20px;
		color: #939393;
		font-weight: bold;
		&.active{
			color: #FC1368;
		}
	}
}

.small-desc{
	display: flex;
	align-items: center;
	margin: 0 0px 10px;
	font-size: 0.75rem;
	span{
		color: #402566;
		font-weight: bold;
	}
	small{
		color: #FC1368;
		position: relative;
		padding-left: 12px;
		margin-left: 8px;
		line-height: 1;
		font-weight: bold;
		&:before{
			content:'';
			width: 6px;
			height: 6px;
			border-radius: 100%;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto 0;
			background: #FC1368;
		}
	}
}

.listing-content{
	margin: 30px -17px;
	.column{
		padding: 0 17px;
		margin-bottom: 32px;
	}
	.inner{
		display: block;
		border-radius: 10px;
		overflow: hidden;
		height: 100%;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
		transition: .2s all ease-out;
		position: relative;
		top: 0;
		background: #fff; 
		&:hover{
			box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.08);
			top: -2px;
			figure{
				img{
					transform: scale(1.07);
				}
			}
		}
	}
	figure{
		height: 195px;
		overflow: hidden;
		position: relative;
		img{
			width: 100%;
			height: 100%; 
			object-fit: cover; 
			object-position: center; 
			transition: .2s all ease-out;
		}
		.btn-play{
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			width: 48px;
			height: 51px;
			margin:auto;
		}
	}
	.text{
		font-size: 0.875rem;
		padding: 24px;
		h5{ 
			text-transform: uppercase;
			font-weight: bold;
			color: #333;
			margin-bottom: 10px;
		}
		h5,p{
		font-size: 0.875rem;
		}
		p { 
			font-weight: 600;
			margin-bottom: 5px; 			     
		}
		.link{
			text-decoration: underline;
			color: #FC1368;
			font-weight: bold;
			margin-top: 10px;
			text-transform: uppercase;
		}
	}
	&.news {
		.text {
			p {
				display: -webkit-box;
	      -webkit-line-clamp: 3;
	      -webkit-box-orient: vertical;
	      overflow: hidden;
			}
		}
	}
}
.wrap-video-middle{
	position: relative;
	margin: 60px 0px;
	.thumb-video{
		position: relative;
		figure{
			position: relative;
			padding: 10px 10px 5px; 
			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: url('/images/material/frameBannerbig.png')no-repeat center;
				z-index: 1;
				background-size: 100% 100%;
				width: 100%;
				height: 100%; 
			}
			img{
				display: block;
				width: 100%;
				position: relative;
				z-index: 2;
			}
		}
		.btn-play{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			z-index: 3; 
		}
	}
	.wrap-yt{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: none;
		iframe{
			width: 100%;
			height: 100%;
		}
		&.show {
			z-index: 3; 
		}
	}
}
.img-with-background{
	position: relative;
	padding:13px 15px 15px;
	&:before{
		content:'';
		position: absolute;
		left: 0;
		right:0;
		top: 0;
		bottom: 0;
		background: url('/images/material/frameBannermid.png')no-repeat center;
		background-size: contain;
		z-index: -1;
	}
}
.content-withimg{
	margin: 50px 0;
	p {
		margin-bottom: 16px; 
	}
	.row{
		margin-left: -47px;
    width: calc(100% + 94px);
    align-items: center;
    margin-bottom:60px; 
		.column{
			padding: 0 47px;	
			margin-bottom: 0; 		
		}
		&.listing-market{
			margin: 15px 0;
			margin-left: -10px;
			width: calc(100% + 20px);
			.column{
				padding: 0 10px;
				margin-bottom: 10px; 
			}
		}		
		&:last-child {
			margin-bottom: 0; 
		}
	}
	small{ font-weight: 500;}
	&.padbottom {
		padding-bottom: 80px;
		margin-bottom: 0; 
	}
}

.popup{
	position: fixed; 
	width: 100%;
	height: 100%; 
	top: 0; 
	right: 0; 
	bottom: 0; 
	left: 0; 
  z-index: 999; 
	display: none;
	overflow-y:auto;  
	background: rgba(0, 0, 0, 0.6);
	align-items: center; 
	padding: 50px 0; 
	// .overlay{
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// 	bottom: 0;
	// 	right: 0;
	// 	background: rgba(0, 0, 0, 0.6);
	// }
  .popup-inner{
		width: 830px;
		height: auto;
  	background: #fff;
  	margin:auto; 
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 15px 24px;
    border-radius: 10px;
		z-index: 2;
  }
	figure{
		height: auto;
		padding: 15px 10px 30px;
		img{
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	.wrap-iframe{
		height: 535px;
		margin-bottom: 15px;
		iframe{
			width: 100%;
			height: 100%;
		}
	}
	h6{
		color: #333;
		font-weight: bold;
	}
}
.data-popup{
	display: none;
}
.img-middle{
	margin: 40px 0px;
	img{
		display: block;
		width: 100%;
		border-radius: 10px; 
	}
}

.title-leftright{
  display: flex;
  align-items: center;
  padding-top: 15px; 
  h2{
    color:#FC1368 ;
    position: relative;
    display: inline-block;
    padding-right: 10px;
    margin-bottom: 15px;
    &:after{
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background: url('/images/material/union-3.png')no-repeat center;
      width: 23px;
      height: 33px;
    }
    b{
      color: #402566;
    }
  }
  .left-t{
    max-width: 485px;
  }
  .right-t{
    margin-left: auto;
  }
  &.light{
    color: #fff;
    h2{
      b{ color: #fff;}
      &:after{
        background-image: url('/images/material/union-2.png');
      }
    }
    .button{
      color: #fff;
    }
  }
}
.breadcrumb{
	display: flex;
	margin-bottom: 10px;
	li{
		padding-right: 15px;
		margin-right: 5px;
		position: relative;
		&::after{
			content: ">";
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			font-size: 0.813rem;
		}
		&:last-child{
			margin-right: 0;
			padding-right: 0;
			&::after{ display: none;}
			a{
				cursor: default;
			}
		}
	}
	a{
		font-size: 0.813rem;
		color: #939393;
		font-weight: 500;
		display: block;
	}
}

.listing-market{
	margin: 10px -10px;
	.column {
		margin-bottom: 10px;
		height: 66px;
	}
	figure{
		height: 100%;
		padding: 15px 10px;
		background: #fff;
		box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.08);
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		img{
			width: auto;
			height: auto;
			display: inline-block;
		}
	}
}
/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1600px) {
	.banner-middle {
		figure {
			.text {
				bottom: 20%; 
			}
		}
	}
	.bg-blue-wave {
		.line-dashed {
			top:-52px;  	
		}
	}
}

@media all and (max-width: 1383px) {
	.middle {
		.img-top-left {
			width: 190px;
		}
	}
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
	.loader {
		.innerLoad {
			.logo-load {
				width: 284px;
			}
		}
	}

	.ice-list {
		figure {
			height: 152px; 
		}
		figcaption {
			min-height: 54px; 
			.txt {
				p {
					line-height: 1.3; 
				}
				&.md {
					h6 {
						font-size: 1rem; 
					}
				}
			}
		}
		.slick-arrow {
			top: 50%; 
			bottom: unset; 
			margin-top: -20px; 
		}
		.slick-dots {
			bottom: -35px; 
		}
	}

	.product-list {
		.list {
			figure {
				height: 187px; 
				.new {
					width: 96px;
					height: 96px; 
					padding-left: 15px; 
				}
				img {
					height: 100%; 
				}
			}
			figcaption {
				h4 {
					font-size: 2rem; 
					b {
						font-size: 1.375rem; 
					}
				}
			}
			&:hover {
				figure {
					.hover {
						transform: scale(.85); 
					}
				}
			}
		}
		&.other-product {
			.list {
				margin-bottom: 0; 
			}
		}
	}

	.h-title {
		.txt {
			h3 {
				&:after {
					width: 18px;
					height: 25px;
					background-size: 100%; 
				}
			}
		}
	}

	.slick-arrow {
		width: 32px; 
		height: 32px; 
		background-size: 32px 32px; 
		&:after {
			background-size: 9px 13px; 
		} 
		&:hover {
			&:after {
				left: 0; 
			}
		}		
	}

	.banner-middle {
		figure {
			.text {
				bottom: 12%; 
				h2 {
					font-size: 2.25rem; 
				}
				span {
					padding: 12px 25px 16px;
				}
			}
		}
	}
	.middle {
		padding-bottom: 80px; 
		min-height: 450px; 
		&.misc-pages {
			height: auto; 
		}
		&.detail-pages {
			padding-top: 120px;
		}
		.img-top-left {
			top: -3px; 
			left: -80px;
			width: 155px;  
			&.med {
				top: 0;
				left: -50px; 
				width: 190px; 
			}
		}
		.img-top-right {
			width: 55px; 
		}
		.img-left-abs {
			width: 128px; 
			&.from-bottom-min {
				bottom: -150px; 
			}
			&.med {
				width: 220px;
				top: -38px; 
			}
		}
		.img-right-abs {
			width: 115px; 
			&.med {
				width: 200px; 
				top: 38%; 
			}
		}
		.title-text {
			&.md {
				font-size: 1.75rem; 
			}
			&.sm {
				font-size: 1.5rem; 
			}
		}
	}
	.wrapper-small { 
		.ice-list {
			width: 417px; 
			margin-left: auto;  
			margin-right: auto;  
			margin-bottom: 180px;  
			figure {
				margin-bottom: 36px; 
			}
		}
	}
	.bg-blue-wave {
		&.wavebottom {
			margin-bottom: 130px; 
		}
		&.less-top {
			margin-top: 100px; 
			padding-top: 30px; 
		}
		.line-dashed {
			right: -90px;
    	top: -40px;
		}
		.line-seed {
			width: 40px; 
			bottom: 100px;
		}
		&:before {
			background-size: 100%;
			background-position: left bottom; 
			bottom: 99.5%; 
		}		
		.rounded-title {
			background-size: 276px auto; 
			top: -70px; 
			span {
				font-size: 4rem; 
			}
		}		
		&.wavebottom {
			&:after {
				background-size: 100%;
    		background-position: left 0;
			}
		}
	}

	.listing-content {
		margin: 30px -8px 25px; 
		.column {
			padding: 0 8px; 
			margin-bottom:16px;  
		}
		.text {
			padding: 24px 16px; 	
		}
	}

	.pagination {
		.arr {
			width: 45px; 
			height: 45px; 
		}
	}

	.wrap-video-middle {
		.thumb-video {
			.btn-play {
				img {
					width: 48px; 
				}
			}
		}
	}

	.title-leftright {
		align-items: flex-end; 
	}

	.img-with-background {
		padding: 15px;  
	}

	.content-withimg {
		h3 {
			font-size: 1.5rem; 
		}
		.row {
			width: calc(100% + 34px); 
			margin-left: -17px;
			align-items: flex-start; 			
			.column {
				padding: 0 17px;
				&.column-55 {
					flex:0 0 49%;
					max-width: 49%; 
				}
				&.column-45 {
					flex:0 0 51%; 
					max-width: 51%; 
				}
			}
		}
	}	

	.popup {
		.wrap-iframe {
			height: 435px; 
		}
		figure {
			padding: 12px 0; 
		}		
	}
	

}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) { 
	.loader {
		.innerLoad {
			.logo-load { 
				width: 241px; 
			}
		}
	}

	.slick-dots {
		bottom: 30px; 
	}

	.ice-list {
		figure {
			height: 118px; 
			margin-bottom: 35px; 
		}
		figcaption {
			min-height: 42px; 
			.txt {
				&.md {
					h6 {
						font-size: .875rem; 
					}
				}
			}
		}
	}

	.product-list {
		.list {
			figure {
				height: 126px; 
				.new {
					width: 72px;
			    height: 72px;
			    background-size: 100%;
			    font-size: 1.125rem;
			    line-height: 72px;
			    padding-left: 0;
				}
				.hover {
					&:before {
						width: 100%; 
						height: 100%; 
						background-size: 100% 100%; 
					}
				}
			}
			figcaption {
				h4 {
					font-size: 1.5rem; 
					b {
						font-size: 1.125rem; 
					}
				}
			}
		}
		&.onlanding {
			.list {
				margin-bottom: 25px; 
			}
		}
		&.other-product {
			padding-top: 30px; 
		}
	}

	.h-title {
		margin-bottom: 25px; 
	}

	.banner-middle {
		figure {
			// height: 255px; 
			img {
				height: 100%; 
				object-fit: cover; 
				object-position: center; 
			}
			.text {
				h2 {
					margin-bottom: 0; 
					font-size: 1.75rem; 	
				}
			}
		}
	}

	.wrapper-small {
		.ice-list {
			width: 325px; 
		}
	}

	.filter-pages {
		.col {
			select {
				width: 150px; 
			}
		}
	}

	.middle {
		padding-top: 55px; 
		padding-bottom: 55px; 
		.img-left-abs {
			width: 100px; 
		}
		.img-left-abs {
			&.from-bottom-min {
				bottom: 95px; 
			}
			&.med {
				top: -20px; 
			}
			&.small {
				width: 75px;
			}
		}
		.img-right-abs {
			width: 95px; 
			top: 340px; 
		}
		.img-bottom-left {
			width: 70px; 
		}

		.title-text {
			&.sm {
				font-size: 1.25rem; 
			}
			&.md {
				font-size: 1.5rem; 
			}
		}
		&.no-banner {
			padding-top: 100px; 
		}		
	}

	.bg-blue-wave {		
		.rounded-title {
			top: -70px; 
			h4 {
				font-size: 1rem; 
			}
			span {
				font-size: 3.5rem; 
			}
		}
		.line-dashed {
			width: 223px;
	    right: -60px;
	    top: -30px;
		}
		&.less-top {
			padding-bottom: 0; 
		}
		&.wavebottom {
			margin-bottom: 100px; 
		}
	}

	.img-with-background {
		padding: 8px;
	}

	.pagination {
		.arr {
			width: 38px; 
			height: 38px; 
			background-size: 38px; 
			&:after {
				top: -3px; 
				background-size: 7px 11px;
			}
		}
	}

	.wrap-video-middle {
		margin: 40px 30px; 	
		.thumb-video {
			figure {
				padding: 5px 5px 5px; 
			}
			.btn-play {
				img {
					width: 32px; 
				}
			}
		}
	}

	.title-leftright {
		align-items:center; 
		h2 {
			font-size: 1.75rem; 
		}
		.left-t {
			padding-right: 50px;
		}
	}

	.content-withimg { 
		margin: 40px 0;
		h3 {
			font-size: 1.25rem; 
		}
		.row {
			width: calc(100% + 24px);
    	margin-left: -12px;
			.column {
				padding:0 12px;
				margin-bottom: 0; 
				p {
					margin-bottom: 0; 
				}
				&.column-55 {
					flex:0 0 55%; 
					max-width: 55%;
				}
				&.column-45 {
					flex:0 0 45%; 
					max-width: 45%;
				}
			}
			&.listing-market {
				margin-left: -10px;
    		width: calc(100% + 20px);
				.column {
					padding: 0 3px;
					margin-bottom: 6px; 
				}
				figure {
					height: 100%; 
				}
			}
		}		
	}

	.listing-market {
		// margin: 10px 10px 0 10px; 	
		.column {
			height: 52px; 
		}
		figure {			
			padding: 6px 10px; 
			img {
		    height: 100%;
		    display: inline-block;
		    max-width: 100%;		    
				object-fit:contain; 
			}
		}
	}

	.listing-content {
		margin-bottom: 20px; 
		figure {
			height: 120px;
			.btn-play {
				width: 32px; 
				height: 32px; 
			} 
		}
		.text {
			h5,
			p {
				font-size: .75rem; 
			}
		}
		&.news {
			margin-bottom: 20px; 
		}
	}

	.popup {
		h6 {
			font-size: .875rem; 
		}
		.popup-inner {
			width: 563px; 
		}
		.wrap-iframe {
			height: 335px; 
		}
	}

	

}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) { 
	.loader {
		.innerLoad {
			.logo-load {
				width: 207px; 
				margin-bottom: 20px; 
			}
			.line-load {
				width: 244px;
				height: 5px; 
				span {
					height: 5px;  	
				}
				&:before {
					height: 5px; 
				}
			}
		}
	}

	header {
		height: 66px; 
		.wrapper {
			.logo {
				width: 88px;
				left: 50%;  
			}
			.burger-menu {
				left: 24px; 
			}
			// .right-head {
			// 	.reseller {
			// 		display: none; 
			// 	}
			// }
		}
	}

	.slick-arrow {
		width: 24px; 
		height: 24px; 
		background-size: 24px 24px; 
		&.slick-prev {
			left: 24px;
		}
		&.slick-next {
			right: 24px; 
		}
	}

	.slick-dots {
		li {
			margin-right: 6px;
			button {
				height: 6px; 
			}
			&.slick-active {
				button {
					width: 24px; 
				}
			}
		}
	}

	.ice-list {
		figure {
			height: 110px; 
		}
		figcaption {
			.t-ico {
				font-size: 2.5rem; 
			}
			.txt {
				&.md {
					h6 {
						font-size: .75rem; 
					}
				}
			}
		}
		.slick-arrow {
			margin-top: -10px; 
		}
		.slick-dots {
			bottom: -30px; 
		}
	}

	.banner-middle {    
		figure {
			// height: 258px; 
			.text {
				bottom: 20%; 
				h2 {
					font-size: 1.5rem; 
				}
				span {
					padding: 12px 16px 16px; 
				}
			}
		}
	}
	.bg-blue-wave {
		padding-top: 120px; 
		&:before { 
			height: 74px; 
			background: url('/images/material/wave-blue-small.png') no-repeat 0 0;
			background-size: 100% 100%;
		}
		&.wavebottom {
			&:after { 
				height: 72px; 
				background: url('/images/material/wave-blue-bottom-small.png') no-repeat 0 0;
				background-size: 100% 100%;
			}
		}
		.img-left-abs {
			margin-bottom: -50px; 
		}
		.line-dashed {
			right: -80px; 
		}
		.rounded-title {
			top: -110px; 
			background-size: 246px auto;
			h4 {
				font-size: .875rem; 
			}
			span {
				font-size: 2.5rem;
			}
		}
		&.less-top {
			margin-top: 80px; 
			padding-bottom: 20px;
		}
	}

	.middle {
		padding-top: 40px; 
		padding-bottom: 40px; 
		.img-left-abs { 
			width: 68px;
			&.from-bottom {
				bottom: 177px; 
			}
			&.md {
				top: -15px; 
			}
		}
		.img-right-abs { 
			width: 80px; 
			top:22%; 
		}
		.img-top-left { 
			left: -120px; 
	    top: 0px; 
	    width: 160px; 
	    &.md {
	    	left: -70px; 
	    }
		}
		&.detail-pages {
			.img-top-left {
				top: 66px; 
			}
		}		

		.title-text {
			&.md {
				font-size: 1.125rem;  
			}
		}

		&.detail-pages {
			padding-top: 95px; 
		}

	}

	.img-middle {
		margin-bottom: 30px; 
	}

	.wrap-video-middle {
		margin-left: 0; 
		margin-right: 0; 
		.thumb-video {
			.btn-play {
				width: 24px; 
			}
		}
	}

	.filter-pages {
		flex-direction: column; 
		.col {
			margin-bottom: 12px; 
			width: 100%; 
			&:last-child {
				margin-bottom: 0; 
			}
			label {
				width: 90px; 
				margin-right: 15px; 
				font-size: .75rem; 
			} 
			select {
				width: calc(100% - 105px); 
    		margin-left: 0;
    		height: 36px; 
    		line-height: 36px; 
    		font-size: .75rem; 
			}
		}
	}

	.small-desc {
		font-size: .625rem; 
	}

	.pagination {
		a {
			margin: 0 15px; 
		}
	}

	.listing-content {
		margin: 30px 0 25px;
    flex-direction: row;
    .column {
    	flex: 0 0 50% !important;
    	max-width: 50% !important; 
    	// height: 81px;  
    }
    figure {     	
    	height: auto;
    	.btn-play {
    		width: 26px;  
    		height: 26px;  
    		img {
    			height: auto; 	
    		}
    	}
    }
    .text {
    	padding: 16px 8px; 
    	h5, p {
    		font-size: .625rem; 
    	}
    	.link {
    		font-size: .625rem; 
    	}
    }
	}

	.title-leftright {
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 16px; 
		h2 {
			margin-bottom: 0; 
		}
		.left-t {
			width: 100%; 
			padding-right: 0;  
			margin-bottom: 16px; 
			max-width: 100%; 
		}
		.right-t {
			width: 100%; 
		}
	}

	.content-withimg {
		margin: 40px 30px 0; 
		h3 {
			font-size: 18px; 
		}
		.row {
			margin-bottom: 40px; 
			.column {
				&.column-55 {
					flex: 0 0 100%;
    			max-width: 100%;
    			order:2;
				}
				&.column-45 {
					flex: 0 0 100%;
    			max-width: 100%;
					order:1; 
					margin-bottom: 20px;  
				}

			}
			&.listing-market {
				.column {
					height: 40px; 
				}
				figure {
					padding:4px 8px; 
				}
			}
		}
		&.padbottom {
			padding-bottom: 50px; 
		}
	}

	.product-list {
		&.onlanding {
			.list {
				flex: 0 0 50%;
    		max-width: 50%;
			}
		}
	}

	.breadcrumb { 
		li {
			&:after {
				top: -2px; 
			}
		}
		a {
			font-size: .625rem; 
		}
	}

	.listing-market { 
		margin: 10px 0 0 0;  		
		flex-direction: row; 
		.column {
			padding: 0 3px;
			flex:0 0 50%; 
      max-width: 50%; 	
      height: 40px;			
		}	
	}

	.popup {
		.popup-inner {
			width: 90%; 
			padding: 14px;  
		}
		h6 {
			font-size: .75rem; 
		}
		.wrap-iframe {
			height: 235px; 
		}
		.small-desc {
			margin-bottom: 5px; 
		}
		.text {
			h6 {
				margin-bottom: 0; 
			}
		}
	}

}