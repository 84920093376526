@import "../milligram/Color"; // import your general color from milligram

/* header
----------------------------------------------------------------------------------------------*/
header {
  position: fixed;
  width: 100%;
  height: 120px;
  top: 0;
  left: 0;
  z-index: 888;
  transition: all 0.3s ease-out;
  &:before {
    position: absolute;
    width: 100%;
    height: 0;
    content: "";
    background: $color-initial;
    z-index: 1;
    transition: all 0.3s ease-out;
  }
  .wrapper {
    max-width: none;
    padding: 0 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
    z-index: 3;
    .burger-menu {
      position: absolute;
      width: 18px;
      height: 16px;
      top: 50%;
      left: 27px;
      flex-flow: column;
      justify-content: space-between;
      cursor: pointer;
      transform: translateY(-50%);
      display: none;
      span {
        display: block;
        width: 100%;
        height: 3px;
        background: #fff;
        border-radius: 0px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 50px;
        transition: all ease-out 0.25s;
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2) {
          top: 6px;
        }
        &:nth-child(3) {
          top: 12px;
        }
      }
      &.active {
        z-index: 999;
        span {
          background: #fff !important;
          &:nth-child(2) {
            width: 80%;
            right: unset;
          }
        }
      }
    }
    .close-mob {
      position: absolute;
      width: 16px;
      height: 16px;
      background: url(/images/material/close.png) no-repeat 0 0;
      top: 33px;
      left: 80%;
      margin-left: -70px;
      z-index: 5;
      cursor: pointer;
      display: none;
    }
    nav {
      flex: 0 0 45%;
      max-width: 45%;
      .search-mob {
        display: none;
      }
      .reseller-mob {
        display: none;
      }
      ul {
        display: flex;
        li {
          position: relative;
          padding: 27px 0;
          a {
            color: $color-initial;
            font-size: 0.875rem;
            padding: 0 15px;
            font-weight: 700;
          }
          &:first-child {
            a {
              padding-left: 0;
            }
          }
          &.dropdown {
            a {
              &.parent {
                position: relative;
              }
            }
          }
          &.d-product {
            position: static;
          }
          .child-menu {
            position: absolute;
            top: 100%;
            left: 0;
            padding-top: 13px;
            width: 250px;
            visibility: hidden;
            opacity: 0;
            z-index: -1;
            transition: all 0.2s ease-out;
            .inner {
              border-radius: 8px;
              background: #fdfbf5;
              box-shadow: 0px 21px 45px rgba(0, 0, 0, 0.11);
              padding: 25px;
              position: relative;
              ul {
                display: block;
                margin: 0 0 0 0;
                li {
                  padding: 0;
                  a {
                    padding: 8px 0;
                    font-weight: 500;
                    display: inline-block;
                    color: $color-primary;
                    &:hover {
                      color: $color-secondary;
                    }
                  }
                }
              }
              &:before {
                position: absolute;
                width: 19px;
                height: 16px;
                content: "";
                background: url("/images/material/polygon.png") no-repeat 0 0;
                top: -12px;
                left: 25px;
              }
              .c-left {
                flex: 0 0 25%;
                max-width: 25%;
                position: relative;
                padding: 0 20px 0 0;
                ul {
                  margin-bottom: 10px;
                  li {
                    a {
                      padding: 5px 0;
                      font-weight: 400;
                    }
                  }
                }
                &:after {
                  position: absolute;
                  width: 4px;
                  height: 100%;
                  content: "";
                  top: 0;
                  right: 0;
                  background: url("/images/material/dot-v.png") repeat-y 0 0;
                }
                h6 {
                  font-family: "Montserrat";
                  color: $color-secondary;
                  font-weight: 700;
                  margin: 0 0 10px 0;
                  a {
                    padding: 0;
                    color: $color-secondary;
                  }
                }
              }
              .c-right {
                flex: 0 0 75%;
                max-width: 75%;
                padding: 0 0 0 57px;
                .h-title {
                  margin-bottom: 20px;
                  h3 {
                    margin-bottom: 0px;
                    color: $color-primary;
                  }
                }

                .product-list {
                  .list {
                    padding: 0 9px;
                    margin: 0;
                    flex: 0 0 33%;
                    max-width: 33.33%;
                    figure {
                      height: auto;
                      img {
                        position: relative;
                      }
                      .new {
                        top: 10px;
                        right: -15px;
                      }
                      .hover {
                        &:before {
                          width: 100%;
                          height: 100%;
                          background-size: auto 97%;
                          background-position: center;
                        }
                      }
                    }
                    figcaption {
                      h4 {
                        color: $color-primary;
                        font-family: "Montserrat";
                        font-weight: 500;
                        font-size: 1rem;
                        b {
                          font-family: "Montserrat";
                          font-weight: 500;
                          font-size: 1rem;
                          display: inline-block;
                          padding-right: 4px;
                        }
                      }
                    }
                  }
                }
              }
            }
            &.type-2 {
              width: calc(100% - 120px - 145px);
              left: 0;
              padding-top: 13px;
              margin: 0 120px 0 145px;
              margin-top: -20px;
              .inner {
                padding: 45px 70px 25px 70px;
                display: flex;
                flex-wrap: wrap;
                &:before {
                  left: 155px;
                }
              }
            }
          }
          &:hover {
            .child-menu {
              visibility: visible;
              opacity: 1;
              z-index: 5;
            }
          }
        }
      }
    }
    .logo {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease-out;
      .logo-secondary {
        display: none;
      }
    }
    .right-head {
      flex: 0 0 40%;
      max-width: 40%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .reseller {
        a {
          display: inline-block;
          height: 41px;
          line-height: 41px;
          border: 1px solid #fff;
          border-radius: 30px;
          padding: 0 29px;
          color: $color-initial;
          font-weight: 700;
          font-size: 0.875rem;
        }
      }
      .language {
        margin-right: 30px;
        position: relative;
        > span {
          align-items: center;
          display: flex;
          > a {
            color: $color-initial;
            display: flex;
            &:hover {
              opacity: 0.9;
            }
            > img {
              display: inline-block;
              height: auto;
              margin-left: 4px;
              width: auto;
            }
          }
          > img {
            margin-right: 4px;
          }
        }
        &-box {
          background: $color-initial;
          border-radius: 8px;
          box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
          display: none;
          left: 50%;
          padding: 24px;
          position: absolute;
          top: calc(100% + 16px);
          transform: translateX(-50%);
          width: 249px;
          &::before {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $color-initial;
            border-radius: 10px;
            bottom: 98%;
            content: "";
            height: 0;
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
            width: 0;
          }
          a {
            color: #402566;
            display: block;
            margin-bottom: 18px;
            &.active {
              color: #fc1368;
              font-weight: 600;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .search {
        margin-right: 30px;
        position: relative;
        > img {
          cursor: pointer;
          display: block;
          height: auto;
          margin: auto;
          width: auto;
        }
        .search-box {
          background: var(--color-initial);
          box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
          border-radius: 40px;
          display: none;
          height: 44px;
          min-height: 0;
          opacity: 0;
          padding: 0;
          position: absolute;
          right: 0;
          top: -5px;
          width: 300px;
          z-index: 99;
          form {
            height: 100%;
            margin-bottom: 0;
            position: relative;
            input[type="text"] {
              border: 0;
              border-radius: 40px;
              color: #5f1d8f;
              line-height: 100%;
              height: 100%;
              margin-bottom: 0;
              padding: 0 50px 0 58px;
              position: relative;
              width: 100%;
            }
            button {
              background: transparent;
              border-color: transparent;
              border-radius: 40px 0 0 40px;
              color: var(--color-initial);
              cursor: pointer;
              height: 100%;
              margin-bottom: 0;
              padding-left: 16px;
              position: absolute;
              left: 0;
              text-align: center;
              top: 0;
              width: auto;
              z-index: 1;
              > img {
                display: block;
                filter: invert(81%) sepia(2%) saturate(0%) hue-rotate(65deg)
                  brightness(95%) contrast(91%);
                height: auto;
                margin: auto;
                opacity: 0.3;
                position: relative;
                width: auto;
              }
            }
            .close {
              cursor: pointer;
              padding: 14px 16px 14px 16px;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 1;
              > img {
                display: block;
                filter: invert(81%) sepia(2%) saturate(0%) hue-rotate(65deg)
                  brightness(95%) contrast(91%);
                height: auto;
                margin: auto;
                opacity: 0.3;
                position: relative;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
  &.float,
  &.detail {
    height: 78px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
    &:before {
      height: 100%;
    }
    .burger-menu {
      span {
        background: $color-primary;
      }
    }
    .wrapper {
      nav {
        ul {
          li {
            a {
              color: $color-primary;
            }
          }
        }
      }
      .logo {
        top: 0;
        .logo-primary {
          display: none;
        }
        .logo-secondary {
          display: block;
        }
      }
      .right-head {
        .reseller {
          a {
            color: $color-secondary;
            border-color: $color-secondary;
          }
        }
        .language > span > a {
          color: #402566;
        }
        .search > img {
          filter: invert(9%) sepia(95%) saturate(2694%) hue-rotate(259deg)
            brightness(56%) contrast(84%);
        }
      }
    }
  }
}

.box-chat {
  position: fixed;
  min-width: 55px;
  min-height: 54px;
  right: 28px;
  bottom: 50px;
  z-index: 55;
  .icon {
    position: absolute;
    width: 55px;
    height: 54px;
    top: 0;
    left: 0;
  }
}

/* footer
----------------------------------------------------------------------------------------------*/
footer {
  background: #402566;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  position: relative;
  z-index: 10;
  .list-social {
    display: flex;
  }
  .footer-top {
    color: $color-initial;
    .wrapper {
      position: relative;
      z-index: 5;
      padding: 40px 0;
      .box-left {
        display: flex;
        .logo-foot {
          flex: 0 0 128px;
          margin-right: 35px;
        }
      }
      .menu-foot {
        padding: 0 100px;
        .row {
          justify-content: space-between;
        }
        ul {
          li {
            a {
              color: $color-initial;
              transition: all 0.3s ease-out;
              display: inline-block;
              padding: 1px 0;
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }
      .box-follow {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        h5 {
          font-size: 1.75rem;
        }
        .list-social {
          .list {
            margin: 0 2px;
            transition: all 0.3s ease-out;
            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    position: relative;
    height: 108px;
    font-weight: 700;
    .wrapper {
      z-index: 3;
      height: 100%;
      padding: 50px 36px 0 36px;
      display: flex;
      justify-content: space-between;

      align-items: center;
      max-width: 100%;
      .copyright {
        opacity: 0.8;
        margin-bottom: 0;
      }
      .other-link {
        ul {
          display: flex;
          li {
            a {
              color: $color-initial;
              padding: 0 12px;
              opacity: 0.8;
              transition: all 0.3s ease-out;
              &:hover {
                opacity: 1;
              }
            }
            &:last-child {
              a {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
    &:before {
      position: absolute;
      width: 100%;
      height: 113px;
      content: "";
      background: url("/images/material/foot-bot-1.png") no-repeat 0 0;
      background-size: cover;
      background-position: center;
      z-index: 1;
      top: -20px;
      left: 0;
    }
    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      background: url("/images/material/foot-bot-2.png") no-repeat 0 0;
      background-size: cover;
      background-position: center;
      z-index: 2;
      bottom: 0;
      left: 0;
    }
  }
}

/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1600px) {
  header {
    .wrapper {
      nav {
        ul {
          li {
            a {
              font-size: 0.8125rem;
              padding: 0 10px;
            }
          }
        }
      }
    }
  }
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  header {
    height: 78px;
    .wrapper {
      justify-content: flex-end;
      padding: 0 24px;
      .burger-menu {
        display: flex;
        &.active {
          display: none;
          ~ .right-head .language {
            margin-right: -10px;
            opacity: 1;
            transition: 0.2s ease all;
            transition-delay: 0.3s;
            visibility: visible;
            z-index: 99;
            &-box {
              left: unset;
              transform: unset;
              right: 0;
              &::before {
                left: unset;
                margin: unset;
                right: 22px;
              }
            }
          }
        }
      }
      .close-mob {
        display: block;
        top: 26px;
        left: 54px;
        margin-left: 0;
      }
      nav {
        display: block;
        position: fixed;
        left: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.8);
        flex: unset;
        max-width: none;
        .search-mob {
          display: block;
          position: relative;
          border: 0;
          form {
            display: flex;
            width: auto;
            position: relative;
            margin: 10px 54px;
            &:before {
              position: absolute;
              width: 100%;
              height: 32px;
              border-radius: 6px;
              background: $color-initial;
              opacity: 0.2;
              content: "";
              z-index: -1;
            }
            ::placeholder {
              color: $color-initial;
              font-size: 0.75rem;
            }
            input {
              height: 32px;
              line-height: 32px;
              width: 100%;
              opacity: 1;
              background: none;
              border: 0;
              padding: 0 40px 0 15px;
              color: $color-initial;
              font-weight: 400;
              font-size: 0.75rem;
            }
            button {
              position: absolute;
              width: 37px;
              height: 100%;
              padding: 0;
              border: none;
              background: none;
              top: 0;
              right: 0;
              font-size: 0;
              &:after {
                position: absolute;
                width: 16px;
                height: 16px;
                background-size: 16px 16px;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
                content: "";
                background: url("/images/material/search.svg") no-repeat 0 0;
              }
            }
          }
        }
        ul {
          flex-direction: column;
          width: 100%;
          padding-top: 78px;
          background: #402566;
          position: fixed;
          top: 0;
          bottom: 0;
          left: -100%;
          margin: auto 0;
          overflow-y: auto;
          &:before {
            position: fixed;
            width: 80%;
            height: 78px;
            top: 0;
            left: 0;
            background: #402566;
            content: "";
            z-index: 3;
            display: none;
          }
          li {
            z-index: 2;
            padding: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.24);
            a {
              padding: 15px 86px 15px;
              text-align: center;
              display: flex;
              justify-content: center;
              &.parent {
                &:after {
                  position: absolute;
                  width: 10px;
                  height: 8px;
                  content: "";
                  background: url("/images/material/arr-down-fff.svg") no-repeat
                    0 0;
                  top: 20px;
                  right: 56px;
                  transition: all 0.3s ease-out;
                }
              }
            }
            &:first-child {
              a {
                padding-left: 65px;
              }
            }
            .child-menu {
              position: relative;
              width: 100%;
              visibility: visible;
              opacity: 1;
              z-index: 5;
              top: 0;
              padding: 0;
              display: none;
              transition: none;
              .inner {
                border-radius: 0;
                &:before {
                  display: none;
                }
                padding: 0;
                ul {
                  position: relative;
                  width: 100%;
                  padding: 10px 0;
                  background: #fdfbf5;
                  left: 0;
                  &:before {
                    display: none;
                  }
                  li {
                    text-align: center;
                  }
                }
                .c-right {
                  .h-title {
                    text-align: center;
                    justify-content: center;
                    h3 {
                      font-size: 1.125rem;
                    }
                  }
                  .product-list {
                    .list {
                      flex-direction: column;
                    }
                  }
                }
              }
              &.type-2 {
                width: 100%;
                margin: 0;
                padding: 0;
                border-radius: 0;
                .inner {
                  border-radius: 0;
                  flex-direction: column;
                  align-items: center;
                  padding: 32px;
                  .c-left {
                    flex: 0 0 100%;
                    max-width: 100%;
                    &:after {
                      display: none;
                    }
                  }
                  .c-right {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 32px 0 0 0;
                    position: relative;
                    margin-top: 20px;
                    &:before {
                      position: absolute;
                      width: 100%;
                      height: 5px;
                      content: "";
                      top: 0;
                      left: 0;
                      background: url("/images/material/dot-h.png") no-repeat 0
                        0;
                    }
                  }
                }
              }
            }
            &.act {
              a {
                &.parent {
                  &:after {
                    transform: rotate(-180deg);
                  }
                }
              }
            }
          }
        }
      }
      .right-head {
        flex: 0 0 60%;
        max-width: 60%;
        .search {
          display: none;
          margin-right: 15px;
        }
        .language {
          margin-right: 15px;
          opacity: 0;
          visibility: hidden;
        }
        .search {
          > img {
            height: 28px;
          }
          .search-box {
            right: -10px;
            top: calc(100% + 16px);
          }
        }
        .reseller {
          a {
            background: url("/images/material/icon-reseller.svg") no-repeat
              center;
            border: none;
            height: 32px;
            line-height: 32px;
            font-size: 0.625rem;
            padding: 0 18px;
            text-indent: -9999px;
          }
        }
      }
      .logo {
        left: 50%;
        width: 104px;
      }
    }
    &.float,
    &.detail {
      .wrapper {
        .logo {
          top: 10px;
          .logo-primary {
            display: none;
          }
          .logo-secondary {
            display: block;
          }
        }
        nav {
          ul {
            li {
              a {
                color: #fff;
              }
            }
          }
        }
      }
    }
    &.head-active {
      .wrapper {
        .logo {
          .logo-primary {
            display: block;
          }
          .logo-secondary {
            display: none;
          }
        }
        nav {
          ul {
            &:before {
              display: block;
            }
          }
        }
        .right-head {
          .search {
            z-index: -1;
          }
        }
      }
    }
  }

  .box-chat {
    bottom: 40px;
  }
  footer {
    .footer-top {
      .wrapper {
        padding-left: 72px;
        padding-right: 72px;
        width: 100%;
        max-width: 100%;
        .row {
          .column {
            &.column-75 {
              flex: 0 0 72%;
              max-width: 72%;
            }
            &.column-25 {
              flex: 0 0 28%;
              max-width: 28%;
            }
          }
        }
        .box-left {
          flex-direction: column;
          display: block;
          margin-top: -10px;
          .logo-foot {
            flex: 0 0 128px;
            margin-right: 35px;
            height: auto;
            display: flex;
            align-items: center;
            align-items: flex-start;
            margin-bottom: 15px;
            img {
              width: 128px;
            }
          }
        }
        .menu-foot {
          padding: 0 20px;
          flex: 0 0 32%;
          max-width: 32%;
          padding-right: 30px;
        }
        .box-follow {
          h5 {
            font-size: 1.5rem;
          }
        }
      }
    }
    .footer-bottom {
      &:before {
        top: -10px;
        background: url("/images/material/foot-bot-1-md.png") no-repeat 0 0;
      }
      .wrapper {
        padding-left: 72px;
        padding-right: 72px;
        padding-top: 40px;
        .other-link {
          ul {
            li {
              a {
                font-size: 0.75rem;
              }
            }
          }
        }
        .copyright {
          font-size: 0.75rem;
        }
      }
    }
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  header {
    .wrapper {
      margin: 0;
      .burger-menu.active ~ .right-head .language {
        margin-right: -42px;
      }
      .close-mob {
        left: 24px;
      }
      .right-head {
        .search {
          display: none;
        }
        .language {
          opacity: 0;
          visibility: hidden;
        }
        .reseller {
          display: block;
        }
      }
      nav {
        .search-mob form {
          margin: 10px 24px;
        }
        .reseller-mob {
          display: block;
          margin-right: 0;
          padding: 40px 24px;
          text-align: center;
          border: none;
          a {
            display: block;
            height: 32px;
            line-height: 32px;
            border: 1px solid #fff;
            border-radius: 30px;
            padding: 0 29px;
            color: $color-initial;
            font-weight: 700;
            font-size: 0.625rem;
          }
        }

        ul {
          width: 100%;
          padding-top: 66px;
          &:before {
            width: 100%;
            height: 66px;
          }
          li {
            a {
              padding-left: 46px;
              padding-right: 46px;
              &.parent {
                &:after {
                  right: 24px;
                }
              }
            }
            &:first-child {
              a {
                padding-left: 24px;
              }
            }
            .child-menu {
              .inner {
                .c-right {
                  .product-list {
                    .list {
                      figure {
                        .new {
                          transform: scale(0.8);
                          right: -25px;
                          top: -15px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.float,
    &.detail {
      height: 66px;
      .wrapper {
        .right-head {
          .search {
            .ico-mob {
              background: url("/images/material/search-2.svg") no-repeat 0 0;
            }
          }
        }
      }
    }
    &.head-active {
      .wrapper {
        .logo {
          left: 50%;
        }
      }
    }
  }

  .box-chat {
    .icon {
      width: 48px;
      height: auto;
    }
  }

  footer {
    .footer-top {
      .wrapper {
        padding: 30px 52px 10px 52px;
        .box-left {
          .logo-foot {
            img {
              width: 104px;
            }
          }
          p {
            font-size: 0.75rem;
          }
        }
        .box-follow {
          h5 {
            font-size: 1.25rem;
            margin-bottom: 12px;
          }
        }
      }
      .row {
        .column {
          &.column-40 {
            flex: 0 0 33%;
            max-width: 33%;
          }
          &.menu-foot {
            flex: 0 0 39%;
            max-width: 39%;
          }
        }
      }
    }
    .footer-bottom {
      font-size: 0.75rem;
      &:before {
        top: -15px;
        background: url("/images/material/foot-bot-1-smd.png") no-repeat 0 0;
        background-size: 100%;
      }
      &:after {
        background: url("/images/material/foot-bot-2-smd.png") no-repeat 0 0;
        background-size: 100%;
      }
      .wrapper {
        padding-left: 52px;
        padding-right: 52px;
      }
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .box-chat {
    right: 20px;
    min-width: 40px;
    min-height: 40px;
    .icon {
      width: 40px;
    }
  }

  footer {
    .footer-top {
      position: relative;
      z-index: 2;
      .wrapper {
        padding: 30px 32px 42px 32px;
        margin-left: 0;
        margin-right: 0;
        .row {
          .column {
            margin-bottom: 24px;
            padding: 0;
            &.column-40 {
              flex: 0 0 100%;
              max-width: 100%;
              order: 2;
            }
            &.menu-foot {
              flex: 0 0 100%;
              max-width: 100%;
              order: 1;
              .row {
                flex-direction: row;
                .column {
                  margin-bottom: 0;
                }
              }
              ul {
                li {
                  a {
                    font-size: 0.75rem;
                  }
                }
              }
            }
            &.column-25 {
              flex: 0 0 100%;
              max-width: 100%;
              order: 3;
            }
            &.column-75 {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
        .box-left {
          .logo-foot {
            img {
              width: 128px;
            }
          }
        }
        .box-follow {
          align-items: center;
          flex-direction: row;
          h5 {
            font-size: 0.875rem;
            margin-bottom: 0;
            margin-right: 12px;
          }
          .list-social {
            .list {
              width: 32px;
            }
          }
        }
      }
    }

    .footer-bottom {
      z-index: 3;
      &:before {
        top: -15px;
        background: url("/images/material/foot-bot-1-sm.png") no-repeat 0 0;
        background-size: 100% 100%;
      }
      &:after {
        background: url("/images/material/foot-bot-2-sm.png") no-repeat 0 0;
        background-size: 100% 100%;
      }
      .wrapper {
        padding-left: 0;
        padding-right: 0;
        margin-left: 32px;
        margin-right: 32px;
        .other-link {
          position: absolute;
          top: -52px;
          left: 0;
          ul {
            li {
              a {
                font-weight: 600;
              }
              &:first-child {
                a {
                  padding-left: 0;
                }
              }
            }
          }
        }
        .copyright {
          font-size: 0.625rem;
          width: 200px;
          margin: auto;
          text-align: center;
        }
      }
    }
  }
}
